<template>
  <div class='wrapper'>
    <el-upload
      v-if='isShow'
      :action="picsPath()"
      list-type="picture-card"
      :multiple="imgLimit>1?true:false"
      :on-preview="handlePictureCardPreview"
      :limit="imgLimit"
      :file-list="imgsPreviewList"
      :before-upload="handleBeforeUpload"
      :on-exceed="handleExceed"
      :on-success="handleSuccess"
      :on-remove="handleRemove"
      class="ps-re"
      :class='{"my_pic": isStyleChange}'
      :show-file-list="!drag"
      :drag="isDrag"
      @change="imgeUrlChange"
    >
      <i class="el-icon-plus"></i>
      <div slot="file" slot-scope="{file}">
        <img
          class="el-upload-list__item-thumbnail"
          :src="file.url" alt=""
        >
        <span class="el-upload-list__item-actions">
          <span
            v-if="!isStyleChange"
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in"></i>
          </span>
          <span
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
          >
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <div v-if='!isShow' class='wrapper-img' @mouseenter="moveEnter" @mouseleave="moveLeave">
      <img :src='imgsPreviewList[0].url' class='img'/>
      <div class='delete img' :class="{'active': !isShow}" v-if='deleteShow'> <i class="el-icon-delete" @click="handleRemove(imgsPreviewList[0])"></i></div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="加载失败" />
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    dataPic: Object,
    // 是否显示文字内容
    hideText: Boolean,
    // 隐藏图片列表
    drag: Boolean,
    // 是否支持拖拽
    isDrag:{
      type:Boolean,
      default:false
    },
    //图片回填预览
    imgsPreview: String,
    //图片上传限制
    imgLimit: {
      type: Number,
      default: 6
    },
    //图片尺寸
    imgSize: {
      type: String,
      default: "690*420"
    },
    //样式
    isStyleChange:{
      type: Boolean,
      default: false
    },
    //图片限制大小
    imgVolume: {
      type: Number,
      default: 500
    },
    //超出图片张数提示
    exceedInfo: {
      type: String,
      default: "图片"
    },
    //组件传参，如遍历数组的索引
    params: {
      type: Object
    },
    toEnable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // 响应
      baseimgsPreview:this.imgsPreview,
      // 用于上传图片的预览功能
      dialogImageUrl: "",
      dialogVisible: false,
      imgsPreviewList: [],
      imgData: [],
      size: {
        width: 0,
        height: 0
      },
      isShow: true,
      deleteShow: false
    };
  },
  watch: {
    imgData(val) {
      if(this.isStyleChange){
        this.isShow = val.length ? false : true
      }
    },
    imgsPreview(val){
      if(val && val.length>0){
        this.imgsPreviewList = this.picRefill(this.imgsPreview);
        this.isShow = false
      }
      console.log(val)
    }
  },
  computed: {
    switchStatus: function () {
      console.log(this.baseimgsPreview)
      return this.baseimgsPreview
    }
  },
  mounted() {
    console.log(this.imgsPreview)
    let imgSizeArr = this.imgSize && this.imgSize.split("*");
    this.size.width = imgSizeArr[0];
    this.size.height = imgSizeArr[1];
    this.imgsPreviewList = this.picRefill(this.imgsPreview);
    if(this.imgsPreviewList.length && this.isStyleChange){
        this.isShow = false 
      }
    // console.log(this.dataPic)
  },
  methods: {
    // 图片预览方法
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 图片超出限定数量
    handleExceed(file, fileList) {
      if (fileList.length == 1) {
        this.$message({
          message: `${this.exceedInfo}只可上传1张`,
          type: "warning"
        });
      } else {
        this.$message({
          message: `${this.exceedInfo}最多上传6张`,
          type: "warning"
        });
      }
    },
    //上传前校验
    handleBeforeUpload(file) {
      const that = this;
      let limitSize = 0.5 * 1024 * 1024;
      if (file.size >= limitSize) {
        this.$message.error(`图片大小超过500kb，请重新选择合规的图片`);
        return false;
      }
      const img = new Image();
      const URL = window.URL || window.webkitURL;
      img.src = URL.createObjectURL(file);
      img.onload = function() {
        if (this.width > that.size.width || this.height > that.size.height)
          that.$message.warning(
            `为了更好的浏览效果，建议图片尺寸${that.imgSize}`
          );
      };
    },
    //成功上传
    handleSuccess(respone, file, fileList) {
      // // console.log(respone)
      if(respone.code == 200){
        if(this.isStyleChange){
          this.isShow = false
        }
        this.imgData.push(respone.body)
        this.imgsPreviewList.push({
          name: this.imgsPreviewList.length.toString(),
          url: this.picsPath(respone.body)
        })
        this.handleTransfer();
      }else{
        this.$message.error(respone.msg)
      }
    },
    //移除图片
    handleRemove(res) {
      // // console.log(res)
      let img = res.url.split('/')
      this.imgData = this.imgData.filter(item => item != img[img.length-1])
      this.imgsPreviewList = this.imgsPreviewList.filter(item => item.url != res.url)
      this.handleTransfer()
    },
    //图片回填
    picRefill(paths) {
      if (!paths) return [];
      let pics_arr = [];
      if (paths.indexOf(",") == -1) {
        pics_arr.push({
          name: "1",
          url: this.picsPath(paths)
        });
        return pics_arr;
      }
      if (paths.indexOf(",") != -1) {
        let paths_arr = paths.split(",");
        if (paths_arr) {
          paths_arr.forEach((item, index) => {
            pics_arr.push({
              name: index,
              url: this.picsPath(item)
            });
          });
        }
        return pics_arr;
      }
    },
    //将图片地址返回给父组件
    handleTransfer() {
      console.log(this.imgData)
      this.$emit(
        "getImgData",
        this.imgData,
        this.dataPic
      );
    },
    imgeUrlChange(){
      console.log(this.imgData)
      console.log(this.dataPic)
    },
    moveEnter(){
      // // console.log('enter')
      this.deleteShow = true
    },
    moveLeave(){
      // // console.log('leave')
      this.deleteShow = false
    },
  }
};
</script>
<style lang="scss" scoped>
.my_pic >>> .el-upload--picture-card, .my_pic >>> .el-upload-list--picture-card .el-upload-list__item{
  width: 50px;
  height: 50px;
  line-height: 55px;
}
.wrapper{
  display: flex;
  justify-content: center;

}
.wrapper-img{
  position: relative;
  width: 50px;
  height: 50px;
  .img{
    width: 100%;
    height: 100%;
    border: 1px solid #DCDFE6;
    border-radius: 5px;
  }
  .delete{
    position: absolute;
    top:0;
    color: #fff;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,.5);
    opacity: 0;
    transition: .3s all;
  }
  .active{
    opacity: 1;
  }
}

</style>